import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { OrderStoreService, PartnerOrderDto } from '../../domain';
import { PartnerType } from '../../../../partners/data';
import { OrderStatus } from '../../data';
import { tawreedTranslationService } from "../../../../../common/translations";
import { GlobalContext } from "../../../../../context";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { Button } from 'primereact/button';

export type PartnersOrdersTableProps = {
    returned?: boolean;
    statusId: OrderStatus;
    partnerType: PartnerType;
}

export const PartnersOrdersTable: React.FC<PartnersOrdersTableProps> = (props) => {



    const history = useHistory();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: OrderStoreService = React.useMemo(() => new OrderStoreService(), []);

    const onForm = React.useCallback(
        (sender?: PartnerOrderDto): Promise<void> => {
            return new Promise((resolve) => {
                let url = '';
                if (props.returned) {
                    switch (props.statusId) {
                        case OrderStatus.Shipped:
                            url = Routes.SalesOrdersReturnedShippedDetails;
                            break;
                        default:
                            throw new Error('Unsupported order status id');
                    }
                } else {
                    switch (props.statusId) {
                        case OrderStatus.Placed:
                            url = Routes.SalesOrdersPlacedDetails;
                            break;
                        case OrderStatus.Processing:
                            url = Routes.SalesOrdersProcessingDetails;
                            break;
                        case OrderStatus.READY_FOR_DELIVERY:
                            url = Routes.SalesOrdersReadyDetails;
                            break;
                        case OrderStatus.Shipped:
                            url = Routes.SalesOrdersShippedDetails;
                            break;
                        default:
                            throw new Error('Unsupported order status id');
                    }
                }
                if (url && sender && sender.partner && sender.partner.partnerId) {
                    let id = `${sender.partner.partnerId.toString()}`;
                    if (sender.carrier && sender.carrier.carrierId) {
                        id = `${sender.partner.partnerId.toString()}-${sender.carrier.carrierId.toString()}`;
                    } else if (user && user.roleCode === 'STORE_ADMIN' && props.statusId !== OrderStatus.Placed) {
                        id = `${sender.partner.partnerId.toString()}-${user.partnerId}`;
                    } else {

                    }
                    history.push(url.replace(':id', id));
                }
                resolve();
            })
        }
        , [props.returned, props.statusId, user, history]);



    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'partner',
        name: 'tbl.sales.orders.partners',
        title: tawreedTranslationService.translate('lbl_orders_partners_' + props.partnerType + '_' + (props.returned ? 'true' : 'false') + '_' + props.statusId),
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(props.returned, props.statusId, props.partnerType, req),
        },
        columns: props.statusId === OrderStatus.Placed ?
            [
                {
                    dataType: 'text',
                    field: "partner.partnerName",
                    header: "lbl_sales_partners_orders_partner",
                    sortable: true,
                    sortField: 'partnerName',
                    // filter: true,
                    style: CssUtils.width('33%'),
                },
                {
                    dataType: "numeric",
                    field: "totalCount",
                    header: "lbl_sales_partners_orders_total_count",
                    sortable: true,
                    // filter: true,
                    style: CssUtils.width('33%'),
                },
                {
                    field: "totalAmount",
                    header: "lbl_sales_partners_orders_total_amount",
                    sortable: true,
                    // filter: true,
                    ...TawreedTableFormatters.decimal('totalAmount'),
                    style: CssUtils.width('20%'),
                    className: CssUtils.className.field.amount,
                },
                {
                    field: "actions",
                    body:(Options :any) => {
                        return <React.Fragment>
                            <Button
                             type='button'
                             style={{height:"35px"}}
                             icon={PrimeIcons.EYE}
                             className='p-button-success p-button-text'
                             tooltip={tawreedTranslationService.translate('lbl_info')}
                             onClick={()=>{
                                onForm(Options);
                             }}
                            />
                        </React.Fragment>
                    },
                    style:CssUtils.width('10%')
            }
            ]
            :
            [
                {
                    dataType: 'text',
                    field: "partner.partnerName",
                    header: "lbl_sales_partners_orders_partner",
                    sortable: true,
                    sortField: 'partnerName',
                    // filter: true,
                    style: CssUtils.width('25%'),
                },
                {
                    dataType: 'text',
                    field: "carrier.carrierName",
                    header: "lbl_sales_partners_orders_carrier",
                    sortable: true,
                    sortField: 'carrierName',
                    // filter: true,
                    style: CssUtils.width('25%'),
                },
                {
                    dataType: "numeric",
                    field: "totalCount",
                    header: "lbl_sales_partners_orders_total_count",
                    sortable: true,
                    // filter: true,
                    style: CssUtils.width('25%'),
                },
                {
                    field: "totalAmount",
                    header: "lbl_sales_partners_orders_total_amount",
                    sortable: true,
                    // filter: true,
                    ...TawreedTableFormatters.decimal('totalAmount'),
                    style: CssUtils.width('25%'),
                    className: CssUtils.className.field.amount,
                },{
                    field: "actions",
                    body:(Options :any) => {
                        return <React.Fragment>
                            <Button
                             type='button'
                             style={{height:"35px"}}
                             icon={PrimeIcons.EYE}
                             className='p-button-success p-button-text'
                             tooltip={tawreedTranslationService.translate('lbl_info')}
                             onClick={()=>{
                                onForm(Options);
                             }}
                            />
                        </React.Fragment>
                    },
                    style:CssUtils.width('10%')
            }
            ],
        toolbar: {
            actions: [],
        },
        selection: {
            mode: 'single',
        },
        // filter: {
        //     filterDisplay: 'row',
        //     initial: {
        //         'partner.partnerName': { value: null, matchMode: 'contains' },
        //         totalCount: { value: null, matchMode: 'equals' },
        //         totalAmount: { value: null, matchMode: 'equals' },
        //     },
        // },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'partnerName',
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
